import axios from 'axios';
import { FILE_UPLOADED_SUCCESSFULLY_MESSAGE, FILE_UPLOAD_SIZE_ERROR_MESSAGE } from 'components/Common/AlertMessages';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { CLIENT_APP_ID, CLIENT_USER_ROLE_ID, SEND_EMAIL, USER_ID } from 'constants/users';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { YES } from 'pages/ClientProfile/constants';
import { toast } from 'react-toastify';
import { getErrorObject } from 'utils/apiUtils';
import {
  getClientByClientOrgIdSearchBody,
  getClientContactBody,
  getClientContractListSearchBody,
  getClientListSearchBody,
  getClientSearchBody,
  getClientUserListSearchBody,
  getContactDetailsSearchBody,
} from 'utils/clientUtil';
import { setErrors } from './errorHandler';
import {
  AFFORDANCE_FOR_ALTERNATE_GUIDANCE_NO,
  AFFORDANCE_FOR_ALTERNATE_GUIDANCE_YES,
  GRC_API_INTEGRATION_NO,
  GRC_API_INTEGRATION_YES,
  IS_REMEDIATION_ENABLED_NO,
  IS_REMEDIATION_ENABLED_YES,
  REVIEW_CORL_REMEDIATION_RECOMMENDATION_NO,
  REVIEW_CORL_REMEDIATION_RECOMMENDATION_YES,
} from 'pages/ClientProfile/ContractConfiguration/constants';
import { SCOPING_QUESTIONNAIRE_TYPE, SECURITY_QUESTIONNAIRE_TYPE, SUPPLEMENTAL_QUESTIONNAIRE_TYPE } from 'pages/QuestionnaireBuilder/constants';

export const GET_CLIENT_REQUEST = '@@client/GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = '@@client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = '@@client/GET_CLIENT_FAIL';

export const getClient = (id) => async (dispatch) => {
  dispatch({ type: GET_CLIENT_REQUEST });
  try {
    const body = getClientSearchBody(id);
    const response = await axios.post(`/client/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_CLIENT_FAIL,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAIL,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CLIENT_LIST_LOADING = '@client/SEARCH_CLIENT_LIST_LOADING';
export const SEARCH_CLIENT_LIST_SUCCESS = '@client/SEARCH_CLIENT_LIST_SUCCESS';
export const SEARCH_CLIENT_LIST_FAILURE = '@client/SEARCH_CLIENT_LIST_FAILURE';

export const getClientListData =
  (searchText = '', clientOrgId) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CLIENT_LIST_LOADING });
    try {
      let body;
      if (!isNil(clientOrgId)) {
        body = getClientByClientOrgIdSearchBody(clientOrgId);
      } else {
        body = getClientListSearchBody(searchText);
      }
      const response = await axios.post(`/client/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CLIENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_LIST_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CLIENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_CLIENT_USER_LIST_LOADING = '@client/SEARCH_CLIENT_USER_LIST_LOADING';
export const SEARCH_CLIENT_USER_LIST_SUCCESS = '@client/SEARCH_CLIENT_USER_LIST_SUCCESS';
export const SEARCH_CLIENT_USER_LIST_FAILURE = '@client/SEARCH_CLIENT_USER_LIST_FAILURE';

export const getClientUserListData =
  (id, searchText = '') =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CLIENT_USER_LIST_LOADING });
    try {
      const body = getClientUserListSearchBody(id, searchText);
      const response = await axios.post(`/user/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CLIENT_USER_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_USER_LIST_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CLIENT_USER_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const DELETE_CLIENT_USER_LOADING = '@client/DELETE_CLIENT_USER_LOADING';
export const DELETE_CLIENT_USER_SUCCESS = '@client/DELETE_CLIENT_USER_SUCCESS';
export const DELETE_CLIENT_USER_FAILURE = '@client/DELETE_CLIENT_USER_FAILURE';

export const removeClientUser = (clientOrgId, userId, userRoleId) => async (dispatch) => {
  dispatch({ type: DELETE_CLIENT_USER_LOADING });

  let tempFormData = {
    UserId: userId,
    Roles: [{ UserRoleId: userRoleId }],
    AppId: CLIENT_APP_ID,
    OrgId: clientOrgId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/user/${userId}/action/unassign`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_CLIENT_USER_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: DELETE_CLIENT_USER_SUCCESS,
        payload: response.data,
      });
      dispatch(getClientUserListData(clientOrgId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_CLIENT_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_USER = '@client/SET_SELECTED_USER';

export const setSelectedUser =
  (user = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: user,
    });
  };

export const SET_SELECTED_INDEX = '@@user/SET_SELECTED_INDEX';

export const setSelectedIndex =
  (index = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_INDEX,
      payload: index,
    });
  };

export const GET_CLIENT_CONTACTS_LOADING = '@client/GET_CLIENT_CONTACTS_LOADING';
export const GET_CLIENT_CONTACTS_SUCCESS = '@client/GET_CLIENT_CONTACTS_SUCCESS';
export const GET_CLIENT_CONTACTS_FAILURE = '@client/GET_CLIENT_CONTACTS_FAILURE';

export const getClientContacts =
  (id, searchText = '') =>
  async (dispatch) => {
    dispatch({
      type: GET_CLIENT_CONTACTS_LOADING,
    });

    var body = getClientContactBody(id, searchText);

    try {
      const response = await axios.post(`/client/contact/search`, body);

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_CLIENT_CONTACTS_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_CLIENT_CONTACTS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch {
      dispatch({
        type: GET_CLIENT_CONTACTS_FAILURE,
        payload: {},
      });
    }
  };

export const SET_SELECTED_CONTACT = '@client/SET_SELECTED_CONTACT';

export const setSelectedContact =
  (contact = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_CONTACT,
      payload: contact,
    });
  };

export const POST_CLIENT_LOGO_UPLOAD_LOADING = '@client/POST_CLIENT_LOGO_UPLOAD_LOADING';
export const POST_CLIENT_LOGO_UPLOAD_SUCCESS = '@client/POST_CLIENT_LOGO_UPLOAD_SUCCESS';
export const POST_CLIENT_LOGO_UPLOAD_FAILURE = '@client/POST_CLIENT_LOGO_UPLOAD_FAILURE';

export const uploadClientLogo = (clientOrgId, logoFile, callback) => async (dispatch) => {
  dispatch({
    type: POST_CLIENT_LOGO_UPLOAD_LOADING,
  });

  try {
    const formData = new FormData();
    Object.keys(logoFile).forEach((key) => {
      formData.append('files[]', logoFile[key]);
    });

    const response = await axios.post(`/client/${clientOrgId}/logo/action/puturl`, null);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_CLIENT_LOGO_UPLOAD_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: POST_CLIENT_LOGO_UPLOAD_SUCCESS,
        payload: response?.data,
      });

      const fileOptions = {
        body: logoFile[0],
        method: 'PUT',
        headers: {
          'Content-Type': 'image/png, image/jpg',
        },
      };

      const fileRes = await fetch(response?.data?.Data?.Url, fileOptions);

      if (response?.data?.ErrorCount > 0) {
        toast.error(FILE_UPLOAD_SIZE_ERROR_MESSAGE);
      } else {
        toast.success(FILE_UPLOADED_SUCCESSFULLY_MESSAGE);
      }

      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_CLIENT_LOGO_UPLOAD_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_CLIENT_LOGO_LOADING = '@client/POST_CLIENT_LOGO_LOADING';
export const POST_CLIENT_LOGO_SUCCESS = '@client/POST_CLIENT_LOGO_SUCCESS';
export const POST_CLIENT_LOGO_FAILURE = '@client/POST_CLIENT_LOGO_FAILURE';

export const getClientLogo = (clientOrgId, formData) => async (dispatch) => {
  dispatch({
    type: POST_CLIENT_LOGO_LOADING,
  });

  try {
    const response = await axios.post(`/client/${clientOrgId}/logo/action/geturl`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_CLIENT_LOGO_FAILURE,
        payload: '',
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: POST_CLIENT_LOGO_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_CLIENT_LOGO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CLIENT_USER_LOADING = '@@client/ADD_CLIENT_USER_LOADING';
export const ADD_CLIENT_USER_SUCCESS = '@@client/ADD_CLIENT_USER_SUCCESS';
export const ADD_CLIENT_USER_FAILURE = '@@client/ADD_CLIENT_USER_FAILURE';

export const addClientUser = (formData) => async (dispatch) => {
  dispatch({
    type: ADD_CLIENT_USER_LOADING,
  });

  let tempFormData = {
    UserId: USER_ID,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    MiddleName: formData?.MiddleName,
    LastName: formData?.LastName,
    NamePrefix: formData?.NamePrefix,
    Roles: [{ UserRoleId: CLIENT_USER_ROLE_ID }],
    AppId: CLIENT_APP_ID,
    OrgId: formData?.OrgId,
    SendEmail: SEND_EMAIL,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/user`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CLIENT_USER_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CLIENT_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(getClientUserListData(response?.data?.Data?.OrgId));
    }
  } catch {
    dispatch({
      type: ADD_CLIENT_USER_FAILURE,
    });
  }
};

export const UPDATE_CLIENT_USER_LOADING = '@@client/UPDATE_CLIENT_USER_LOADING';
export const UPDATE_CLIENT_USER_SUCCESS = '@@client/UPDATE_CLIENT_USER_SUCCESS';
export const UPDATE_CLIENT_USER_FAILURE = '@@client/UPDATE_CLIENT_USER_FAILURE';

export const updateClientUser = (formData) => async (dispatch) => {
  dispatch({
    type: UPDATE_CLIENT_USER_LOADING,
  });

  let tempFormData = {
    UserId: formData?.UserId,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    MiddleName: formData?.MiddleName,
    LastName: formData?.LastName,
    NamePrefix: formData?.NamePrefix,
    AppId: CLIENT_APP_ID,
    OrgId: formData?.OrgId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.put(`/user/${formData?.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_CLIENT_USER_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_CLIENT_USER_SUCCESS,
        payload: {
          response: response?.data,
        },
      });
      dispatch(getClientUserListData(response?.data?.Data?.OrgId));
    }
  } catch {
    dispatch({
      type: UPDATE_CLIENT_USER_FAILURE,
    });
  }
};

export const SET_CLIENT_USER_FORM_EDITABLE = '@client/SET_CLIENT_USER_FORM_EDITABLE';

export const setClientUserFormEditable =
  (isEditable = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_CLIENT_USER_FORM_EDITABLE,
      isEditable,
    });
  };

export const SEARCH_CLIENT_CONTRACT_LIST_LOADING = '@client/SEARCH_CLIENT_CONTRACT_LIST_LOADING';
export const SEARCH_CLIENT_CONTRACT_LIST_SUCCESS = '@client/SEARCH_CLIENT_CONTRACT_LIST_SUCCESS';
export const SEARCH_CLIENT_CONTRACT_LIST_FAILURE = '@client/SEARCH_CLIENT_CONTRACT_LIST_FAILURE';

export const getClientContractListData = (id, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_CLIENT_CONTRACT_LIST_LOADING });
  try {
    const body = getClientContractListSearchBody(id);
    const response = await axios.post(`/client/current/contract/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_CLIENT_CONTRACT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_CLIENT_CONTRACT_LIST_SUCCESS,
        payload: response?.data,
      });

      if(callback){
        callback(response?.data?.Data);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CLIENT_CONTRACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CLIENT_CONTRACT_LOADING = '@@client/ADD_CLIENT_CONTRACT_LOADING';
export const ADD_CLIENT_CONTRACT_SUCCESS = '@@client/ADD_CLIENT_CONTRACT_SUCCESS';
export const ADD_CLIENT_CONTRACT_FAILURE = '@@client/ADD_CLIENT_CONTRACT_FAILURE';

export const addClientContract = (formData, corlContactId, id) => async (dispatch) => {
  dispatch({
    type: ADD_CLIENT_CONTRACT_LOADING,
  });
  const { RiskAdvisorEmail, ...rest } = formData;
  let tempFormData = {
    ...rest,
    CorlRiskAdvisorEmail: RiskAdvisorEmail?.value,
    CorlRiskAdvisorContactId: RiskAdvisorEmail?.contactId || 0,
    CorlRiskAdvisorFirstName: RiskAdvisorEmail?.firstName,
    CorlRiskAdvisorLastName: RiskAdvisorEmail?.lastName,
    CorlContactId: corlContactId,
    ClientContractId: 0,
    ClientOrgId: id,
    IsTPIRSubscribed: formData?.IsTPIRSubscribed === YES,
    IsPreAssessmentAllowed: formData?.IsPreAssessmentAllowed === YES,
    IsCORLCleared: formData?.IsCORLCleared === YES,
    IsRemediationEnabled: formData?.IsRemediationEnabled === YES ? IS_REMEDIATION_ENABLED_YES : IS_REMEDIATION_ENABLED_NO,
    ReviewCorlRemRecommendationId:
      formData?.ReviewCorlRemRecommendationId === YES ? REVIEW_CORL_REMEDIATION_RECOMMENDATION_YES : REVIEW_CORL_REMEDIATION_RECOMMENDATION_NO,
    GRCApiIntegrationId: formData?.GRCApiIntegrationId === YES ? GRC_API_INTEGRATION_YES : GRC_API_INTEGRATION_NO,
    AffordanceForAlternateRemGuidanceId:
      formData?.AffordanceForAlternateRemGuidanceId === YES ? AFFORDANCE_FOR_ALTERNATE_GUIDANCE_YES : AFFORDANCE_FOR_ALTERNATE_GUIDANCE_NO,
    RemediationPreferences: !isNil(formData?.RemediationPreferences) ? formData?.RemediationPreferences.toString() : '',
    SharepointURL: formData?.SharepointURL.slice(-1) === '/' ? formData?.SharepointURL : `${formData?.SharepointURL}/`,
    AppId: CLIENT_APP_ID,
    SecurityQuestionnaireTemplateList: formData?.SecurityQuestionnaireTemplateList?.map((item) => ({
      QuestionnaireTemplateId: item,
      QuestionnaireTemplateTypeId: SECURITY_QUESTIONNAIRE_TYPE,
    })),
    SupplementalQuestionnaireTemplateList: formData?.SupplementalQuestionnaireTemplateList?.map((item) => ({
      QuestionnaireTemplateId: item,
      QuestionnaireTemplateTypeId: SUPPLEMENTAL_QUESTIONNAIRE_TYPE,
    })),
    ScopingQuestionnaireTemplateList: formData?.ScopingQuestionnaireTemplateList?.map((item) => ({
      QuestionnaireTemplateId: item,
      QuestionnaireTemplateTypeId: SCOPING_QUESTIONNAIRE_TYPE,
    })),
    QuestionnaireEmailReminderSettings: {
      ...formData?.QuestionnaireEmailReminderConfig,
      OutreachAttemptEmailDuration1: Number(formData?.QuestionnaireEmailReminderConfig?.OutreachAttemptEmailDuration1),
      OutreachAttemptEmailDuration2: Number(formData?.QuestionnaireEmailReminderConfig?.OutreachAttemptEmailDuration2),
      OutreachAttemptEmailDuration3: Number(formData?.QuestionnaireEmailReminderConfig?.OutreachAttemptEmailDuration3),
      CCAssessmentRequester1: Number(formData?.QuestionnaireEmailReminderConfig?.CCAssessmentRequester1) === 1,
      CCAssessmentRequester2: Number(formData?.QuestionnaireEmailReminderConfig?.CCAssessmentRequester2) === 1,
      CCAssessmentRequester3: Number(formData?.QuestionnaireEmailReminderConfig?.CCAssessmentRequester3) === 1,
      CCBusinessStakeholder1: Number(formData?.QuestionnaireEmailReminderConfig?.CCBusinessStakeholder1) === 1,
      CCBusinessStakeholder2: Number(formData?.QuestionnaireEmailReminderConfig?.CCBusinessStakeholder2) === 1,
      CCBusinessStakeholder3: Number(formData?.QuestionnaireEmailReminderConfig?.CCBusinessStakeholder3) === 1,
    },
    RemediationEmailReminderSettings: {
      ...formData?.RemediationEmailReminderConfig,
      OutreachAttemptEmailDuration1: Number(formData?.RemediationEmailReminderConfig?.OutreachAttemptEmailDuration1),
      OutreachAttemptEmailDuration2: Number(formData?.RemediationEmailReminderConfig?.OutreachAttemptEmailDuration2),
      OutreachAttemptEmailDuration3: Number(formData?.RemediationEmailReminderConfig?.OutreachAttemptEmailDuration3),
      CCAssessmentRequester1: Number(formData?.RemediationEmailReminderConfig?.CCAssessmentRequester1) === 1,
      CCAssessmentRequester2: Number(formData?.RemediationEmailReminderConfig?.CCAssessmentRequester2) === 1,
      CCAssessmentRequester3: Number(formData?.RemediationEmailReminderConfig?.CCAssessmentRequester3) === 1,
      CCBusinessStakeholder1: Number(formData?.RemediationEmailReminderConfig?.CCBusinessStakeholder1) === 1,
      CCBusinessStakeholder2: Number(formData?.RemediationEmailReminderConfig?.CCBusinessStakeholder2) === 1,
      CCBusinessStakeholder3: Number(formData?.RemediationEmailReminderConfig?.CCBusinessStakeholder3) === 1,
    },
    AllowSecCert: Number(formData?.AllowSecCert),
    AcceptedSecCertTypes: Array.isArray(formData?.AcceptedSecCertTypes) ? formData.AcceptedSecCertTypes.filter(Boolean).join(',') : '',
    RiskRatingRemediationPreferences: Array.isArray(formData?.RiskRatingRemediationPreferences) ? formData.RiskRatingRemediationPreferences.filter(Boolean).join(',') : '',
  };

  delete tempFormData?.QuestionnaireEmailReminderConfig; // Use QuestionnaireEmailReminderSettings only
  delete tempFormData?.RemediationEmailReminderConfig; // Use RemediationEmailReminderSettings only

  const body = JSON.stringify(tempFormData);

  try {
    const response = await axios.post(`/client/contract`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CLIENT_CONTRACT_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CLIENT_CONTRACT_SUCCESS,
        payload: response?.data,
      });
      dispatch(getClientListData('', id));
    }
  } catch {
    dispatch({
      type: ADD_CLIENT_CONTRACT_FAILURE,
    });
  }
};

export const ADD_CLIENT_INFO_LOADING = '@@client/ADD_CLIENT_INFO_LOADING';
export const ADD_CLIENT_INFO_SUCCESS = '@@client/ADD_CLIENT_INFO_SUCCESS';
export const ADD_CLIENT_INFO_FAILURE = '@@client/ADD_CLIENT_INFO_FAILURE';

export const addClientInfo = (formData) => async (dispatch) => {
  dispatch({
    type: ADD_CLIENT_INFO_LOADING,
  });

  const body = {
    ClientOrgName: formData?.ClientOrgName,
    DomainName: formData?.DomainName,
    RegisteredBusinessAddressLine1: formData?.RegisteredBusinessAddressLine1,
    RegisteredBusinessAddressLine2: formData?.RegisteredBusinessAddressLine2,
    RegisteredBusinessAddressCity: formData?.RegisteredBusinessAddressCity,
    RegisteredBusinessAddressState: formData?.RegisteredBusinessAddressState,
    RegisteredBusinessAddressCountry: formData?.RegisteredBusinessAddressCountry,
    RegisteredBusinessAddressZip: formData?.RegisteredBusinessAddressZip,
    PhoneCode: formData?.PhoneCode,
    MainPhone: formData?.MainPhone,
    PhoneExtension: formData?.PhoneExtension,
    NumberOfEmployeesRangeId: formData?.NumberOfEmployeesRangeId,
    YearFounded: parseInt(formData?.YearFounded),
    LogoFileLocation: '',
  };
  try {
    const response = await axios.post(`/client`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CLIENT_INFO_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CLIENT_INFO_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    dispatch({
      type: ADD_CLIENT_INFO_FAILURE,
    });
  }
};

export const SEARCH_CONTACT_LIST_LOADING = '@client/SEARCH_CONTACT_LIST_LOADING';
export const SEARCH_CONTACT_LIST_SUCCESS = '@client/SEARCH_CONTACT_LIST_SUCCESS';
export const SEARCH_CONTACT_LIST_FAILURE = '@client/SEARCH_CONTACT_LIST_FAILURE';

export const getContactDetailsData = (searchText, contactType) => async (dispatch) => {
  dispatch({ type: SEARCH_CONTACT_LIST_LOADING });
  try {
    const body = getContactDetailsSearchBody(searchText);
    const response = await axios.post(`/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_CONTACT_LIST_FAILURE,
        payload: [],
        contactType: contactType,
      });
    } else {
      dispatch({
        type: SEARCH_CONTACT_LIST_SUCCESS,
        payload: response?.data,
        contactType: contactType,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CONTACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const POST_RESEND_INVITE_CLIENT_USER_LOADING = '@@client/POST_RESEND_INVITE_CLIENT_USER_LOADING';
export const POST_RESEND_INVITE_CLIENT_USER_FAILURE = '@@client/POST_RESEND_INVITE_CLIENT_USER_FAILURE';
export const POST_RESEND_INVITE_CLIENT_USER_SUCCESS = '@@client/POST_RESEND_INVITE_CLIENT_USER_SUCCESS';

export const resendInviteClientUser = (reqBody) => async (dispatch) => {
  dispatch({
    type: POST_RESEND_INVITE_CLIENT_USER_LOADING,
  });
  let body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/user/${reqBody.UserId}/action/sendemailinvite`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_RESEND_INVITE_CLIENT_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_RESEND_INVITE_CLIENT_USER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_RESEND_INVITE_CLIENT_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
